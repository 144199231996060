// import
import React, { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Offcanvas, Row } from "react-bootstrap";
import "./booking_user.css";
import BackArrow from "../../../../assets/img/back_arrow.png";
import ContactUsScreen from "../../home/ContactUs/ContactUsScreen"; // Import ContactUsScreen
import img_section_info from "../../../../assets/img/img_section_info.png";
import ConfirmPay1 from "../../../../assets/img/confirm_pay_1.png";
import ConfirmPay2 from "../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../assets/img/confirm_pay_4.png";
import ConfirmPay5 from "../../../../assets/img/confirm_pay_5.png";
import icon_fork from "../../../../assets/img/icon_fork.png";
import NoResultFound from "../../../../assets/img/icon_no_result.png"
import ConfirmPayProfilePicture1 from "../../../../assets/img/confirm_pay_profil_picture_1.png";
import { API_ENDPOINT, IMG_ALT, section4SliderResponsive } from "../../../../constants";
import CustomHr from "../../../reusable/CustomHr";
import { Link } from "react-router-dom";
import { isEqual, set } from "lodash";
import moment from "moment";
import "moment/locale/fr";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import { section4ProductsOptionMariage, ProductsOptionMariage } from "../../../reusable/packoption/ProductsOptionMariage"; // Import the data and component
import { section4ProductsPrestataireMariage, ProductsPrestataireMariage } from "../../../reusable/packoption/ProductsPrestataireMariage"; // Import the data and component
import SectionInformationMessageRequestSent2 from "../../../reusable/SectionInformationMessageRequestSent2.js";

// import - Section - Top
import SectionTop from "../../../reusable/booking/SectionTop";

// import - Section - Event
import SectionEvent from "../../../reusable/booking/SectionEvent";
import Event from "../../event/Event";

// import - Section - Date
import SectionDate from "../../../reusable/booking/SectionDate";
import { RangeDatePicker } from "react-google-flight-datepicker";

// import - Section - Guest
import SectionGuest from "../../../reusable/booking/SectionGuest";
import Guest from "../../guest/Guest";

import SectionItemAdditional from "../../../reusable/booking/SectionItemAdditional";

// import - Section - User
import SectionUser from "../../../reusable/booking/SectionUser";
import Account from "../../account/Account";
import { AuthContext, useAuth } from "../../auth/authContext";
import AuthModal from "../../auth/AuthModal";

// import - Section - Cook
import SectionCook from "../../../reusable/booking/SectionCook";

// import - Section - Access
import SectionAccess from "../../../reusable/SectionAccess";

// import - Section - Access
import SidebarAccess from "../../../reusable/access/SidebarAccess";

// import - Section - Room
import SectionBed from "../../../reusable/booking/SectionRoom";
import Bed from "../../bed/OptionRoom";

// import - Section - Rule and condition
import SidebarRuleAndCondition from "../../../reusable/booking/SidebarRuleAndCondition";

// import - Section - invoice
import InvoiceOffcanvas from "../../../reusable/invoice/SidebarInvoiceList";

// import - Section - Required
import SectionRequired from "../../../reusable/booking/SectionRequired";

// import - Section - Price Detail
import SectionPriceDetail from "../../../reusable/booking/SectionPriceDetail";

// import - Section - Payment method
import Payment from "../../payment/Payment";
import SectionPaymentMethod from "../../../reusable/booking/SectionPaymentMethod";

// import - Section - Clickable
import SectionClickable from "../../../reusable/SectionClickable";
import SectionClickable2 from "../../../reusable/SectionClickable2";
import SectionClickable3 from "../../../reusable/SectionClickable3";

// import - Section - Receipt
import SidebarDocumentRequire from "../../../reusable/documentrequire/SidebarDocumentRequire";

// import - Section - Payment plan
import SectionPaymentPlan from "../../../reusable/booking/SectionPaymentPlan";
import { ErrorToast, SuccessToast } from "../../../reusable/Toast";
import { useDispatch } from "react-redux";
import { getSlotRooms, getTimeSlotById, getTimeSlotsByDate } from "../../../../redux/actions/timeSlot";
import { useSelector } from "react-redux";
import { getRooms } from "../../../../redux/actions/room";
import { createBooking, getBooking, getCards, getClientSecret } from "../../../../redux/actions/booking";
import payment from "../../payment/Payment";
import SectionProfile from "../../../reusable/booking/SectionProfile";


// --------------------------------------------------



/* Const */



/* Const - Initial screen that open */


/* Const - Initial range date select */
const initialReserveDate = {
  startDate: null,
  endDate: null,
};

const DisplayData = ({
  data,
  setSelectedTimeSlot,
  setSelectedDate,
  setSelectedPrice,
  setpriceDetailsSlots,
  isReserveSelectedTimeSlot,
  setIsReserveSelectedTimeSlot,
  isEqual

}) => {
  const [visibleCount, setVisibleCount] = useState(10);
  const currentData = data.slice(0, visibleCount);

  // Function to load the next 10 objects
  const handleSeeMore = () => {
    setVisibleCount(prev => prev + 10);
  };

  useEffect(() => {
    console.log('this is data', data)
  }, [data])

  return (
    <div>
      {
        currentData.map((value, index) => {
          const dayIndices = {
            'monday': 1,
            'tuesday': 2,
            'wednesday': 3,
            'thursday': 4,
            'friday': 5,
            'saturday': 6,
            'sunday': 7
          };

          const frenchDayNames = {
            'monday': 'lundi',
            'tuesday': 'mardi',
            'wednesday': 'mercredi',
            'thursday': 'jeudi',
            'friday': 'vendredi',
            'saturday': 'samedi',
            'sunday': 'dimanche'
          };



          const startHour = parseInt(value?.startHour?.slice(0, 2), 10);
          const endHour1 = parseInt(value?.endHour?.slice(0, 2), 10);
          const startDay = value?.startDay;
          const endDay = value?.endDay;

          function formatDate(inputDate) {
            const months = [
              "janv.", "févr.", "mars", "avr.", "mai", "juin",
              "juil.", "août", "sept.", "oct.", "nov.", "déc."
            ];

            const dateObj = new Date(inputDate);
            const day = dateObj.getUTCDate();
            const month = months[dateObj.getUTCMonth()];
            const dayOfWeek = frenchDayNames[["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][dateObj.getUTCDay()]];

            const formattedDate = `${dayOfWeek} ${day} ${month}`;
            return formattedDate;
          }


          const functionTo = (dateUse, hoursC) => {
            const [hours, minutes, seconds] = hoursC.split(':');
            const newDate = new Date(dateUse);
            newDate.setUTCHours(parseInt(hours, 10));
            newDate.setUTCMinutes(parseInt(minutes, 10));
            newDate.setUTCSeconds(parseInt(seconds, 10));
      
            return moment(newDate);
          }

          let hoursDiff
          if ((startHour === endHour1) && (startDay === endDay)) {
            hoursDiff = 1
          } else {
            let newStartDate = functionTo(value.startDate, value.startHour);
            let newEndDate = functionTo(value.endDate, value.endHour);
            hoursDiff = newEndDate.diff(newStartDate, 'hours');
          }
          return (
            <Row key={index}>
              {value?.isAvailable &&
                <Col>
                  {/* <h3 style={{ fontWeight: '600', fontSize: '18px', marginTop: '26px', textTransform: 'capitalize' }}>{frenchDayNames[value?.startDay?.toLowerCase()].slice(0, 3)} {moment(value?.startDate).format("DD MMM")}</h3> */}
                  <div
                    className="d-flex flex-row align-items-center justify-content-between clt-datepicker-sidebar-timeslot-mainDiv"
                    onClick={() => {
                      console.log('value issss', value)
                      setSelectedTimeSlot(value)
                      console.log('this is time value', value)
                      setSelectedDate({
                        start: `${value?.startDate}, ${value?.startHour}`,
                        end: `${value?.endDate}, ${value?.endHour}`,
                      })



                      setSelectedPrice(value.price)

                      setpriceDetailsSlots([
                        { title: "Créneau", tooltipMsg: null, price: `${value?.price}€` },
                      ])
                      setIsReserveSelectedTimeSlot(value)
                    }}
                    style={
                      isEqual(isReserveSelectedTimeSlot, value)
                        ? {
                          borderColor: `#000`,
                          background: "#f6f6f6",
                        }
                        : {
                          borderColor: `#ddd`,
                          background: "#fff",
                        }
                    }
                  >
                    <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                      <h2></h2>
                      <h3 style={{ fontWeight: '500', textTransform: 'capitalize' }}>{frenchDayNames[value?.startDay?.toLowerCase()].slice(0, 3)} {moment(value?.startDate).format("DD MMM YYYY")} - {value?.startHour?.slice(0, 5)} → </h3>

                      <h3 style={{ fontWeight: '500', textTransform: 'capitalize' }}>{frenchDayNames[value?.endDay?.toLowerCase()].slice(0, 3)} {moment(value?.endDate).format("DD MMM YYYY")} - {value?.endHour?.slice(0, 5)}</h3>

                      <h4 style={{ fontWeight: '400' }}>{value?.price}€</h4>
                      <span className="d-flex justify-content-start align-items-center">
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        />{" "}
                        {hoursDiff} h
                      </span>
                    </div>
                    <div
                      className="inner-div-circle"
                      style={
                        isEqual(isReserveSelectedTimeSlot, value)
                          ? {
                            borderColor: `#000`,
                          }
                          : {
                            borderColor: `#ddd`,
                          }
                      }
                    >
                      <div
                        className="subDiv"
                        style={
                          isEqual(isReserveSelectedTimeSlot, value)
                            ? {
                              background: `#000`,
                            }
                            : {
                              background: `transparent`,
                            }
                        }
                      ></div>
                    </div>
                  </div>
                </Col>
              }
            </Row>
          );
        })
      }
      {visibleCount < data.length && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Button
            style={{ color: '#242424', background: '#eeeeee', border: "none" }}
            variant="secondary"
            onClick={handleSeeMore}
          >
            <span>Voir plus</span>
          </Button>
        </div>
      )}
    </div>
  );
};


/* Const - Booking */
const Booking = ({
  openBookingSidebar,
  eventType,
  setEventType,
  onCloseBookingSidebar,
  isFromBooking,
  isFromRoom,
  isFromBookingDate,
  isFromBookingTimeSlot,
  isFromContactForm,
  isFromContactFormDateTime,
  isFromEditRequest,
}) => {
  /* Const - Booking - Screens */

  moment.locale('fr')
  const selectedSlotRooms = useSelector((state) => state.allSlotRooms.slotRooms);
  const event = useSelector((state) => state.event.event);
  const allEvents = useSelector((state) => state.allEvents.events);
  const section6DetailCategory = event?.detailCategory;
  const sortedsection6DetailCategory = section6DetailCategory?.map(
    (category) => {
      return {
        ...category,
        rules: category.rules.sort((a, b) => a.order - b.order),
      };
    }
  );
  const [myselectedProfile, setMySelectedProfile] = useState();
  console.log('THIS IS MY SELECTED PROFILE', myselectedProfile);
  /* const handleBack = (from, to) => {
    if (from === "bookingconfirmAndPay") {
      isFromRoom && onCloseBookingSidebar();
    } else {
      setBookingScreens((prevState) => ({
        ...prevState,
        [from]: !prevState[from],
        [to]: !prevState[to],
      }));
    }
  };*/

  //date handle to context
  const { setSelectedPrice, setSelectedDate, selectedTimeSlot, setSelectedTimeSlot, bookingScreens, setBookingScreens, initialBookingScreens, onBookingSideBarSet, setOnbookingSideBar, scrolTop, setScrollTop, guestCount, setGuestCount, setGuestCountInput, setSelectTotalRooms, selectedProfile, setSelectedProfile, isPaymentCardSelected, setIsPaymentCardSelected, selectedPlan, setSelectedPlan, bedCount, setBedCount } = useContext(AuthContext);

  const handleBack = (from, to) => {
    setBookingScreens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };
  const handleClose = () => {
    setBookingScreens(initialBookingScreens);
    setIsReserveDate(initialReserveDate);
    setIsReserveSelectedTimeSlot(null);
    setUserToken(null);
    setIsPaymentCardSelected(false);
    setGuestCount(0);
    setBedCount([]);
    onCloseBookingSidebar("Booked");
  };



  /* Const - Booking - Screen : bookingdateAndTimeSlots */

  /* Const - Range date */
  const [isReserveDate, setIsReserveDate] = useState(
    isFromBookingDate ? isFromBookingDate : initialReserveDate
  );
  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
        <h2 className="clt-detail-left-section-2-h2">{rest.title}</h2>
        <div>
          <Button
            className={
              currentSlide === 0
                ? "disable clt-detail-left-section-2-cards-arrowBtn me-2"
                : "clt-detail-left-section-2-cards-arrowBtn me-2"
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? "disable clt-detail-left-section-2-cards-arrowBtn"
                : "clt-detail-left-section-2-cards-arrowBtn"
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
    );
  };

  /* Const - Timeslot */
  const [isReserveTimeSlots, setIsReserveTimeSlots] = useState([
    { id: 1, title: "Title", price: "subTitle", duration_time: "time" },
    { id: 2, title: "Title", price: "subTitle", duration_time: "time" },
  ]);

  /* Const - Timeslot 
  const handleSelectDataTimeSlotcut = () => {
    setGuestCount(0);
    setGuestCountInput(0);
    setSelectTotalRooms([]);
    dispatch(getSlotRooms(selectedTimeSlot.timeSlotId));
    if (isFromContactForm && isFromContactForm) {
      isFromContactFormDateTime(
        `${moment(isReserveDate.startDate).format(
          "DD/MM/YY"
        )} - 23:59 -> ${moment(isReserveDate.endDate).format(
          "DD/MM/YY"
        )} - 23:59`
      );
      onCloseBookingSidebar();
    } else if (isFromEditRequest) {
      setBookingScreens((prevState) => ({
        ...prevState,
        bookingdateAndTimeSlots: false,
        bookinginformationMsg: true,
      }));
    } else {
      setBookingScreens((prevState) => ({
        ...prevState,
        bookingdateAndTimeSlots: false,
        bookingconfirmAndPay: true,
      }));
    }
  };*/



  const handleSelectDataTimeSlot = () => {
    // Check if the contact sidebar is already open
    if (!open) {
      // Display ErrorToast if not open
      ErrorToast(
        "Information",
        "Pour proceder à une réservation, veuillez contacter le château."
      );
    }
  
    // Open the contact sidebar
    //setOpen(true);
  };

  
  const [open, setOpen] = useState(false);



  const [isReserveSelectedTimeSlot, setIsReserveSelectedTimeSlot] = useState(
    isFromBookingTimeSlot ? isFromBookingTimeSlot : null
  );

  const [currentTimeSlot, setCurrentTimeSlot] = useState(null)

  useEffect(async () => {
    if (isReserveSelectedTimeSlot?.timeSlotId) {
      const response = await dispatch(getTimeSlotById(isReserveSelectedTimeSlot?.timeSlotId))

      if (response.error) {
        return
      }
      else {
        setCurrentTimeSlot(response)
      }
    }
  }, [isReserveSelectedTimeSlot]);








  /* Const - Booking - Screen : bookingconfirmAndPay */

  /* Const - Section - Event */
  const [isEventSidebar, setIsEventSidebar] = useState(false);

  /* Const - Section - Date */
  let onePrice = 0;

  const handleDateEdit = () => {
    setBookingScreens((prevState) => ({
      ...prevState,
      bookingdateAndTimeSlots: true,
      bookingconfirmAndPay: false,
    }));

  };

  /* Const - Section - Guest */
  const [isGuestSidebar, setIsGuestSidebar] = useState(false);
  // const [guestCount, setGuestCount] = useState(0);

  /* Const - Section room */
  const [isBedSidebar, setIsBedSidebar] = useState(false);

  useEffect(() => {
    isFromRoom &&
      setBookingScreens({
        bookingdateAndTimeSlots: false,
        bookingconfirmAndPay: true,
        guestCounter: false,
        bedCounter: false,
        bookingsuccess: false,
      });
  }, [isFromRoom]);

  /* Const - Section - User profile */
  const [isAccountInfo, setIsAccountInfo] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const { isLoggedIn, user, socket } = useAuth();
  const [profileType, Setprofiletype] = useState();
  const [name, setName] = useState('')
  useEffect(() => {
    if (isLoggedIn && user) {
      console.log("running");
      setName(user?.firstName);
    } else {
      setName("");
    }

    if (socket) {
      socket.on('cancelRequestSent', function (dataComing, callback) {
        setMybooking(dataComing.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user]);

  const toggleAuthModal = () => {
    setIsLoginModal((prevState) => !prevState);
    setUserToken("null");
  };

  /* Const - Section - invoice */
  const [isInvoiceDetail, setIsInvoiceDetail] = useState(false);

  /* Const - Section - Rules and conditions */
  const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] = useState(false);

  /* Const - Section - Document Require */
  const [isSidebarDocumentRequire, setIsSidebarDocumentRequire] = useState(false);


  /* Const - Section - Access */
  const [isSidebarAccess, setIsSidebarAccess] = useState(false);

  /* Const - Section - Price detail */
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Slot", tooltipMsg: null, price: "15€" },
  ]);
  const [priceDetailsRooms, setpriceDetailsRooms] = useState([]);
  const [paybuttondisable, setpaybuttondisable] = useState(false);
  const [priceDetailsDamage, setpriceDetailsDamage] = useState([
    {
      title: "Caution",
      tooltipMsg:
        "Caution: Un état des lieux sera réalisé avant et après votre réservation. Une caution pourra être prélevé automatiquement pour les dégâts subis.",
      price: "Prélevement automatique en cas de dommage.",
    },
  ]);

  /* Const - Section - Payment plan */


  /* Const - Section - Payment method */
  const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);
  const [isLoadingDate, setIsLoadingDate] = useState(false);


  /* Const - Button - Pay */
  const [myBooking, setMybooking] = useState({})
  const [updatedBooking, setUpdatedbooking] = useState({})
  const [selectedBooking, setSelectedBooking] = useState({});
  const calculateTotal = () => {
    const priceListData = priceDetailsSlots.concat(
      priceDetailsRooms,
    )
    let onePrice = 0
    console.log(priceListData);
    for (let i = 0; i < (priceListData?.length); i++) {
      console.log(priceListData[i]);
      console.log(priceListData[i]?.price?.replace(/[^0-9.-]+/g, ''));
      if (priceListData[i]?.price) {
        const price = parseFloat(priceListData[i]?.price?.replace(/[^0-9.-]+/g, ''));
        onePrice += price
      }
    }
    return onePrice;
  }

  const handlePay = async () => {
    setpaybuttondisable(true);
    try {
      const priceListData = priceDetailsSlots.concat(
        priceDetailsRooms,
      )
      let onePrice = 0
      console.log(priceListData);
      for (let i = 0; i < (priceListData?.length); i++) {
        console.log(priceListData[i]);
        console.log(priceListData[i]?.price?.replace(/[^0-9.-]+/g, ''));
        if (priceListData[i]?.price) {
          const price = parseFloat(priceListData[i]?.price?.replace(/[^0-9.-]+/g, ''));
          onePrice += price
        }
      }

      let selectedRooms = []

      for (let i = 0; i < (bedCount?.length); i++) {
        const id = bedCount[i].id
        selectedRooms.push(id)
      }
      console.log("MY PROFILE");
      console.log(profileType);
      let errFlag = false;
      let errMsg = "Information manquante";
      console.log("Profile")
      console.log(selectedPlan);
      if (!eventType || !eventType.id) {
        errFlag = true;
        errMsg += "\n Évenement";
      }
      if (!selectedTimeSlot || !selectedTimeSlot.id) {
        errFlag = true;
        errMsg += "\n Créneau";
      }
      if (!guestCount) {
        errFlag = true;
        errMsg += "\n Invité";
      }
      if (!myselectedProfile && !myselectedProfile[0]?.id) {
        errFlag = true;
        errMsg += "\n Profil";
      }
      if (Object.keys(selectedPlan).length === 0) {
        errFlag = true;
        errMsg += "\n Plan de paiement";
      }
      if (Object.keys(isPaymentCardSelected).length === 0) {
        errFlag = true;
        errMsg += "\n Moyen de paiement";
      }
      // errFlag = true;
      console.log(selectedTimeSlot);
      if (errFlag) {
        ErrorToast(errMsg);
        setpaybuttondisable(false);
      } else {
        const data = {
          eventId: eventType.id,
          userId: 1,
          childTimeSlotId: selectedTimeSlot.id,
          price: onePrice,
          guest: guestCount,
          rooms: bedCount || [],
          profile_type: profileType,
          profileId: myselectedProfile[0]?.id ? myselectedProfile[0].id : null,
          paymentmethodId: isPaymentCardSelected?.id,
          selectedPlan: selectedPlan,
          notificationData: {
            title: "Notification: Booking - Comming",
            message: "Upcoming reservation"
          }
        }

        const dataToPass = JSON.stringify(data)
        const cardDetails = {
          number: parseInt(isPaymentCardSelected?.number),
          expMonth: isPaymentCardSelected?.expMonth,
          expYear: isPaymentCardSelected?.expYear,
          cvv: isPaymentCardSelected?.cvv
        }
        console.log(isPaymentCardSelected);
        const response = await dispatch(createBooking(dataToPass, cardDetails))
        setpaybuttondisable(false);
        if (response.error) {
          console.log('Error while creating booking')
        } else {
          let changeResponse = response.message;
          changeResponse.BookingDocuments = [];
          setMybooking(changeResponse)
          // const updtbooking = await dispatch(getBooking(response?.message?.id));
          // setUpdatedbooking(updtbooking);
          setBookingScreens((prevState) => ({
            ...prevState,
            bookingconfirmAndPay: false,
            bookingsuccess: true,
          }));
        }
      }
    } catch (e) {
      console.error(e);
      setpaybuttondisable(false);
      ErrorToast("Information manquante", "Veuillez saisir toutes les informations pour créer votre réservation.")
    }


  };

  const dispatch = useDispatch();
  const timeSlots = useSelector((state) => state.timeSlotsByDate.timeSlotsByDate);

  const [currentEventSlots, setCurrentEventSlots] = useState({
    returnSlots: [],
    returnSlotsAfter: [],
    returnSlotsBefore: []
  });

  useEffect(() => {
    let dataToSet = {
      returnSlots: {},
      returnSlotsAfter: [],
      returnSlotsBefore: []
    }
    if (timeSlots?.returnSlots) {
      if (timeSlots.returnSlots.every((value) => !value?.isAvailable)) {
        dataToSet.returnSlots = {};
      } else {
        const categorizedData = timeSlots.returnSlots.reduce((acc, obj) => {
          const formattedDate = moment(obj.startDate).format('dddd DD MMMM');
          const date = formattedDate;
          if (!acc[date] && obj.isAvailable === true) {
            acc[date] = [];
          }
          if (acc[date]) {
            acc[date].push(obj);
          }

          return acc;
        }, {});
        dataToSet.returnSlots = categorizedData;
      }
    } else {
      dataToSet.returnSlots = {};
    }

    if (timeSlots?.returnSlotsAfter) {
      if (timeSlots.returnSlotsAfter.every((value) => !value?.isAvailable)) {
        dataToSet.returnSlotsAfter = [];
      } else {
        dataToSet.returnSlotsAfter = timeSlots.returnSlotsAfter;
      }
    } else {
      dataToSet.returnSlotsAfter = [];
    }

    if (timeSlots?.returnSlotsBefore) {
      if (timeSlots.returnSlotsBefore.every((value) => !value?.isAvailable)) {
        dataToSet.returnSlotsBefore = [];
      } else {
        dataToSet.returnSlotsBefore = timeSlots.returnSlotsBefore;
      }
    } else {
      dataToSet.returnSlotsBefore = [];
    }

    setCurrentEventSlots(dataToSet);
    setIsLoadingDate(false);
  }, [timeSlots])

  function convertToStartOfDay(inputDate) {
    const date = new Date(inputDate);
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0); // Set hours to 23 and reset minutes, seconds, and milliseconds
    return date.toISOString();
  }

  const handleDateChange = (startDate, endDate) => {
    setIsLoadingDate(true);
    setIsReserveSelectedTimeSlot(null)
    setIsReserveDate({
      startDate: startDate,
      endDate: endDate,
    })
    if (startDate && endDate) {
      let dateToSend = new Date();
      dateToSend.setHours(0, 0, 0, 0);
      const data = {
        minDate: dateToSend.toISOString(),
        startTime: startDate,
        endTime: convertToStartOfDay(endDate),
        eventId: eventType?.id,
      }
      const dataToSend = JSON.stringify(data)
      console.log('This is data to send', dataToSend)
      dispatch(getTimeSlotsByDate(dataToSend))
    }
  }

  const handleEventchange = (id) => {
    setIsLoadingDate(true);
    setIsReserveSelectedTimeSlot(null)
    setIsReserveDate({
      startDate: mystartDate,
      endDate: myendDate,
    })
    if (mystartDate && myendDate) {
      const data = {
        startTime: mystartDate,
        endTime: myendDate,
        eventId: id,
      }
      const dataToSend = JSON.stringify(data)
      dispatch(getTimeSlotsByDate(dataToSend))
    }
  }

  useEffect(() => {
    handleDateChange(null, null)
  }, [eventType])

  const [mystartDate, setStartDate] = useState("");
  const [myendDate, setEndDate] = useState("");

  //   const selectedDate = document.querySelector('#start-date-input-button > .selected-date');

  //   const monthNameMap = {
  //     'Jan': 'Jan',
  //     'Feb': 'Fév',
  //     'Mar': 'Mar',
  //     'Apr': 'Avr',
  //     'May': 'Mai',
  //     'Jun': 'Juin',
  //     'Jul': 'Juil',
  //     'Aug': 'Août',
  //     'Sep': 'Sept',
  //     'Oct': 'Oct',
  //     'Nov': 'Nov',
  //     'Dec': 'Déc',
  //   };

  //   console.log({selectedDate})

  //   // if(selectedDate) {
  //     const parts = selectedDate.textContent.split(',')

  //     if (parts.length > 1) {
  //       const monthDayParts = parts[1].trim().split(' ');
  //       if (monthDayParts.length > 1) {
  //         const monthEnglish = monthDayParts[1];
  //         const monthFrench = monthNameMap[monthEnglish];

  //         // Now, replace the English month with the French one
  //         selectedDate.textContent = selectedDate.textContent.replace(monthEnglish, monthFrench);
  //       }
  //     }
  //   // }
  //   // const selectedDate = startDateInput.querySelector('.selected-date');



  //   // selectedDates.forEach((element) => {
  //   //   console.log(element.textContent);

  //   //   const parts = element.textContent.split(',');  // split by comma
  //   //   if (parts.length > 1) {
  //   //     const monthDayParts = parts[1].trim().split(' '); // split by space
  //   //     if (monthDayParts.length > 1) {
  //   //       const monthEnglish = monthDayParts[1];
  //   //       console.log({monthEnglish})
  //   //       const monthFrench = monthNameMap[monthEnglish];

  //   //       // Now, replace the English month with the French one
  //   //       element.textContent = element.textContent.replace(monthEnglish, monthFrench);
  //   //     }
  //   //   };
  //   // });
  // }, [mystartDate])

  // --------------------------------------------------
  const offcanvasRef = useRef(null);
  const scrollToTop = () => {
    if (offcanvasRef.current) {
      const scrollableElement = offcanvasRef.current;
      scrollableElement.scrollTop = 0;
    }
  };

  const [isProductsSidebarListOpen, setIsProductsSidebarListOpen] = useState(false);
  const { isProductsSidebarOpen, setIsProductsSidebarOpen } = useContext(AuthContext);

  const openProductsSidebarList = () => {
    setIsProductsSidebarListOpen(true);
    console.log(eventType?.title)

    if (
      eventType?.title === 'Mariage' ||
      eventType?.title === 'Séminaire' ||
      eventType?.title === 'Repas noël' ||
      eventType?.title === 'Anniversaire' ||
      eventType?.title === 'Baptème' ||
      eventType?.title === 'Convention' ||
      eventType?.title === 'Soirée' ||
      eventType?.title === 'Cousinade' ||
      eventType?.title === 'Afterwork'
    ) {
      setIsProductsSidebarOpen(true);
    }
  };

  const handlePaymentMethods = () => {
    console.log("in handle payments");
    if (isLoggedIn) {
      dispatch(getClientSecret());
      dispatch(getCards(1));
      setIsPaymentSidebar(true)
    } else {
      toggleAuthModal();
    }
  }



  // useEffect(() => {
  //   if (bookingScreens.bookingconfirmAndPay) {
  //     scrollToTop();
  //   }
  // }, [bookingScreens.bookingconfirmAndPay]);
  useEffect(() => {

    // Scroll to the top when bookingScreens.bookingconfirmAndPay becomes true
    if (bookingScreens.bookingconfirmAndPay) {
      scrollToTop();
    }
    if (bookingScreens.bookingsuccess) {
      scrollToTop();
    }
    if (bookingScreens.bookingdateAndTimeSlots) {
      scrollToTop();
    }
  }, [bookingScreens.bookingconfirmAndPay, bookingScreens.bookingsuccess, bookingScreens.bookingdateAndTimeSlots]);

  const openInvoices = async () => {
    // const updtbooking = await dispatch(getBooking(myBooking?.id));
    const response = await fetch(`${API_ENDPOINT}/booking/${myBooking?.id}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
      }
    });
    const result = await response.json()
    console.log('awa');
    setMybooking(result);
    console.log('awa');
    setIsInvoiceDetail(true)
  }
  useEffect(() => {
    setBedCount([]);
    setpriceDetailsRooms([])
  }, [selectedTimeSlot])
  return (
    <>

      <div className="notranslate" >


        {/* Booking - Screens */}
        <Offcanvas
          placement="end"
          className="clt_products_offcanvas"
          show={openBookingSidebar}
          onHide={
            bookingScreens.bookingsuccess ? handleClose : onCloseBookingSidebar
          }
        >



          {/* Header - Booking - Screens */}
          <Offcanvas.Header className="justify-content-start clt_products_offcanvas_header">

            {/* Header - Booking - Screen : bookingdateAndTimeSlots */}
            {bookingScreens.bookingdateAndTimeSlots && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn-close btn-back shadow-none d-flex"
                  aria-label="Close"
                  onClick={onCloseBookingSidebar}
                >
                  <img src={BackArrow} className="" alt={IMG_ALT} />
                </button>
                <Offcanvas.Title>Selectionner un créneau</Offcanvas.Title>
              </React.Fragment>
            )}

            {/* Header - Booking - Screen : bookingconfirmAndPay */}
            {bookingScreens.bookingconfirmAndPay && (
              <React.Fragment >
                <button
                  type="button"
                  className="btn-close btn-back shadow-none d-flex"
                  aria-label="Close"
                  onClick={() => handleBack("bookingconfirmAndPay", "bookingdateAndTimeSlots")}
                >
                  <img src={BackArrow} className="" alt={IMG_ALT} />
                </button>
                <Offcanvas.Title>Confirmer et payer</Offcanvas.Title>
              </React.Fragment>
            )}

            {/* Header - Booking - Screen : Booking success page */}
            {bookingScreens.bookingsuccess && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn-close shadow-none d-flex"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
                <Offcanvas.Title>Réservation confirmer</Offcanvas.Title>
              </React.Fragment>
            )}


            {/* Header - Booking - Screen : bookinginformationMsg */}
            {bookingScreens.bookinginformationMsg && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn-close btn-back shadow-none d-flex"
                  aria-label="Close"
                  onClick={() => handleBack("bookinginformationMsg", "bookingdateAndTimeSlots")}
                >
                  <img src={BackArrow} className="" alt={IMG_ALT} />
                </button>
                <Offcanvas.Title>Confirmer et payer</Offcanvas.Title>
              </React.Fragment>
            )}
          </Offcanvas.Header>



          {/* Body - Booking - Screens */}
          <Offcanvas.Body
            className={`clt_mybooking_offcanvas_body2 ${bookingScreens.bookinginformationMsg &&
              `d-flex flex-column justify-content-center align-items-center`
              }`}
            ref={offcanvasRef}
          >

            {/* Body - Booking - Screens : bookingdateAndTimeSlots */}
            {bookingScreens.bookingdateAndTimeSlots && (
              <>
                <div className="clt_mybooking_offcanvas_body">

                  {/* Range date */}
                  <Row>
                    <Col>
                      <h2 className="clt-datepicker-sidebar-h2">Date de l'évenement</h2>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col className="clt-custom-datepicker">
                      <div onClick={() => {
                        console.log('clicking')
                        // Add a slight delay to change the language
                        setInterval(() => {
                          // Define an array to map month names
                          const monthNameMap = {
                            'Jan': 'Jan',
                            'Feb': 'Fév',
                            'Mar': 'Mar',
                            'Apr': 'Avr',
                            'May': 'Mai',
                            'Jun': 'Juin',
                            'Jul': 'Juil',
                            'Aug': 'Août',
                            'Sep': 'Sept',
                            'Oct': 'Oct',
                            'Nov': 'Nov',
                            'Dec': 'Déc',
                          };

                          // Get all elements with the class name "month-name"
                          const monthNameElements = document.querySelectorAll('.month-name');

                          // Loop through the elements and update their text content
                          monthNameElements.forEach((element) => {
                            const currentText = element.textContent.trim();
                            // const monthMatch = currentText.match(/^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/);
                            // if (monthMatch && monthNameMap[monthMatch[0]]) {
                            //   element.textContent = currentText.replace(monthMatch[0], monthNameMap[monthMatch[0]]);
                            // }
                            const monthMatch = monthNameMap[currentText.split(' ')[0]]

                            if (monthMatch) {
                              element.textContent = currentText.replace(currentText.split(' ')[0], monthMatch);
                            }
                          });
                        }, 300); // Delay in milliseconds
                        const dayNameMap = {
                          'Mo': 'Lu',
                          'Tu': 'Ma',
                          'We': 'Me',
                          'Th': 'Je',
                          'Fr': 'Ve',
                          'Sa': 'Sa',
                          'Su': 'Di',
                        };

                        // Get all elements with the class name "weekday"
                        const weekdayElements = document.querySelectorAll('.weekday');

                        // Loop through the elements and update their text content
                        weekdayElements.forEach((element) => {
                          const currentText = element.textContent;
                          if (dayNameMap[currentText]) {
                            element.textContent = dayNameMap[currentText];
                          }
                        })

                        // Get all elements with the class "flipper-button"
                        const flipperButtons = document.querySelectorAll('.flipper-button');

                        // Add a click event listener to each element
                        flipperButtons.forEach((element) => {
                          element.addEventListener('click', () => {
                            // Add a slight delay to change the language
                            setTimeout(() => {
                              // Define an array to map month names
                              const monthNameMap = {
                                'Jan': 'Jan',
                                'Feb': 'Fév',
                                'Mar': 'Mar',
                                'Apr': 'Avr',
                                'May': 'Mai',
                                'Jun': 'Juin',
                                'Jul': 'Juil',
                                'Aug': 'Août',
                                'Sep': 'Sept',
                                'Oct': 'Oct',
                                'Nov': 'Nov',
                                'Dec': 'Déc',
                              };

                              // Get all elements with the class name "month-name"
                              const monthNameElements = document.querySelectorAll('.month-name');

                              // Loop through the elements and update their text content
                              monthNameElements.forEach((element) => {
                                const currentText = element.textContent.trim();
                                // const monthMatch = currentText.match(/^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/);
                                // if (monthMatch && monthNameMap[monthMatch[0]]) {
                                //   element.textContent = currentText.replace(monthMatch[0], monthNameMap[monthMatch[0]]);
                                // }
                                const monthMatch = monthNameMap[currentText.split(' ')[0]]

                                if (monthMatch) {
                                  element.textContent = currentText.replace(currentText.split(' ')[0], monthMatch);
                                }
                              });
                            }, 300); // Delay in milliseconds


                          });
                        });

                        const button = document.querySelector('.btn-outline.reset-button');
                        const doneBtn = document.querySelector('.submit-button');
                        const resetMobileBtn = document.querySelector('.reset-button.mobile');
                        if (resetMobileBtn) {
                          resetMobileBtn.innerHTML = "Effacer";
                        } else {
                          console.log('No reset button')
                        }
                        if (button) {
                          const innerHTMLFromClass1 = button.innerHTML;
                          console.log("Inner HTML for 'btn-outline':", innerHTMLFromClass1);
                          button.innerHTML = 'Effacer';

                          // If you want to get the innerHTML for 'reset-button' class as well
                          // You can create a temporary element with only the 'reset-button' class
                          const tempElement = document.createElement('div');
                          tempElement.classList.add('reset-button');
                          tempElement.innerHTML = button.innerHTML;

                          const innerHTMLFromClass2 = tempElement.innerHTML;
                          console.log("Inner HTML for 'reset-button':", innerHTMLFromClass2);
                        } else {
                          console.log("Button with classes 'btn-outline reset-button' not found.");
                        }
                        if (doneBtn) {

                          doneBtn.innerHTML = 'Confirmer';

                        }

                      }}>
                        <RangeDatePicker
                          startDate={isReserveDate.startDate}
                          endDate={isReserveDate.endDate}
                          onChange={(startDate, endDate) => {
                            setStartDate(startDate)
                            setEndDate(endDate)
                            handleDateChange(startDate, endDate)

                            const startDateText = document.querySelectorAll('#start-date-input-button > .selected-date')
                            const endDateText = document.querySelectorAll('#end-date-input-button > .selected-date')

                            if (startDate) {
                              startDateText.forEach((element) => {
                                element.textContent = moment(startDate).format("ddd DD MMM")
                                element.style.textTransform = "capitalize";
                              })
                            }

                            if (endDate) {
                              endDateText.forEach((element) => {
                                element.textContent = moment(endDate).format("ddd DD MMM")
                                element.style.textTransform = "capitalize";
                              })
                            }

                          }
                          }
                          minDate={new Date()}
                          monthFormat="MMM YYYY"
                          highlightToday={true}
                          startDatePlaceholder="Arrivée"
                          endDatePlaceholder="Départ"
                          disabled={false}
                          className=""
                          startWeekDay="monday"
                          locale="fr"
                        />
                      </div>
                    </Col>
                  </Row>
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

                  {/* Timeslot */}

                  {!isLoadingDate ? (
                    <Row>
                      <Col>
                        <h2 className="clt-datepicker-sidebar-h3">Créneau</h2>
                        <p className="aaclt_admin_dash_timeslot_edit_box_subheading1801">Retrouvez la liste des créneaux disponible ci-dessous, les prix affichés concernent le château sans les options.</p>
                      </Col>
                    </Row>
                  ) :
                    <Row>
                      <Col>
                        <h2 className="clt-datepicker-sidebar-h3">Créneau</h2>
                      </Col>
                    </Row>}
                  {isReserveDate.startDate && isReserveDate.endDate ? (
                    <>
                      <Accordion className="clt-section-5-accordion" defaultActiveKey="null">
                        {(currentEventSlots?.returnSlots.length >= 0 || currentEventSlots?.returnSlotsAfter.length >= 0 || currentEventSlots?.returnSlotsBefore.length >= 0) && !isLoadingDate ?
                          Object.keys(currentEventSlots).map((key, indexT) => (
                            <div key={indexT}>
                              {
                                key === 'returnSlots' ?
                                  Object.keys(currentEventSlots[key]).length === 0 ?
                                    <>
                                      <Accordion.Item
                                        eventKey="NoSlotAvailable"
                                        //className="clt-section-5-accordion-item"
                                        style={{ border: 'none' }}
                                      >
                                        <Accordion.Header className="clt-timeslots-accordion-header">
                                           <div>
                                              <p style={{ marginBottom: 0 }}>Durant vos dates</p>
                                              <p style={{ color: '#70757a', fontWeight: 'normal', marginBottom: 0 }}>Aucun créneau disponible</p>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body className="clt_select_timeslot_accordion_body">
                                          <div className="no-data-found my-3">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                              <img src={NoResultFound} alt={IMG_ALT} height={'auto'} width={'150'} />
                                              <div className=" ml-0 d-flex flex-column justify-content-center align-items-center clt-booking-cp-div3-text clt-booking-cp-div3-textt">
                                                <h2>Aucun créneau disponible</h2>
                                                <p>Veuillez saisir de nouvelles dates</p>

                                              </div>
                                              {/* ...... */}
                                              {/* <h2 className="clt-no-slot-error">No time slot is available in this range of time</h2> */}
                                            </div>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <CustomHr hrClass="p-0 m-0 clt-hr" />
                                    </>
                                    :
                                    Object.keys(currentEventSlots[key]).map((keyS, indexS) => (
                                      <>
                                        <Accordion.Item
                                          eventKey={`${keyS}`}
                                          //className="clt-section-5-accordion-item"
                                          style={{ border: 'none' }}
                                        >
                                          <Accordion.Header className="clt-timeslots-accordion-header">
                                            <div>
                                              <p style={{ marginBottom: 0 }}>{`${keyS}`}</p>
                                              <p style={{ color: '#70757a', fontWeight: 'normal', marginBottom: 0 }}>{`${currentEventSlots[key][keyS].length} créneau(x) disponible`}</p>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body className="clt_select_timeslot_accordion_body">
                                            <DisplayData
                                              data={currentEventSlots[key][keyS]}
                                              setSelectedTimeSlot={setSelectedTimeSlot}
                                              setSelectedDate={setSelectedDate}
                                              setSelectedPrice={setSelectedPrice}
                                              setpriceDetailsSlots={setpriceDetailsSlots}
                                              isReserveSelectedTimeSlot={isReserveSelectedTimeSlot}
                                              setIsReserveSelectedTimeSlot={setIsReserveSelectedTimeSlot}
                                              isEqual={isEqual}
                                            />
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <CustomHr hrClass="p-0 m-0 clt-hr" />
                                      </>
                                    ))
                                  :
                                  key === 'returnSlotsAfter' ?
                                    currentEventSlots[key].length === 0 ?
                                      <>
                                        <Accordion.Item
                                          eventKey="NoSlotAvailableReturnSlotsAfter"
                                          //className="clt-section-5-accordion-item"
                                          style={{ border: 'none' }}
                                        >
                                          <Accordion.Header className="clt-timeslots-accordion-header">
                                            <div>
                                              <p style={{ marginBottom: 0 }}>Avant vos dates</p>
                                              <p style={{ color: '#70757a', fontWeight: 'normal', marginBottom: 0 }}>{`${currentEventSlots[key].length} créneau(x) disponible`}</p>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body className="clt_select_timeslot_accordion_body">
                                            <div className="no-data-found my-3">
                                              <div className="d-flex flex-column justify-content-center align-items-center">
                                                <img src={NoResultFound} alt={IMG_ALT} height={'auto'} width={'150'} />
                                                <div className=" ml-0 d-flex flex-column justify-content-center align-items-center clt-booking-cp-div3-text clt-booking-cp-div3-textt">
                                                  <h2>Aucun créneau disponible</h2>
                                                  <p>Aucun créneau n'est disponible avant vos dates</p>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <CustomHr hrClass="p-0 m-0 clt-hr" />
                                      </>
                                      :
                                      <>
                                        <Accordion.Item
                                          eventKey={`${currentEventSlots[key]}`}
                                          //className="clt-section-5-accordion-item"
                                          style={{ border: 'none' }}
                                        >
                                          <Accordion.Header className="clt-timeslots-accordion-header">
                                            <div>
                                              <p style={{ marginBottom: 0 }}>Avant vos dates</p>
                                              <p style={{ color: '#70757a', fontWeight: 'normal', marginBottom: 0 }}>{`${currentEventSlots[key].filter(x => x.isAvailable === true).length} créneau(x) disponible`}</p>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body className="clt_select_timeslot_accordion_body">
                                            <DisplayData
                                              data={currentEventSlots[key]}
                                              setSelectedTimeSlot={setSelectedTimeSlot}
                                              setSelectedDate={setSelectedDate}
                                              setSelectedPrice={setSelectedPrice}
                                              setpriceDetailsSlots={setpriceDetailsSlots}
                                              isReserveSelectedTimeSlot={isReserveSelectedTimeSlot}
                                              setIsReserveSelectedTimeSlot={setIsReserveSelectedTimeSlot}
                                              isEqual={isEqual}
                                            />
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <CustomHr hrClass="p-0 m-0 clt-hr" />
                                      </>
                                    :
                                    key === 'returnSlotsBefore' ?
                                      currentEventSlots[key].length === 0 ?
                                        <>
                                          <Accordion.Item
                                            eventKey="NoSlotAvailableReturnSlotsBefore"
                                            //className="clt-section-5-accordion-item"
                                            style={{ border: 'none' }}
                                          >
                                            <Accordion.Header className="clt-timeslots-accordion-header">
                                              <div>
                                                <p style={{ marginBottom: 0 }}>Après vos dates</p>
                                                <p style={{ color: '#70757a', fontWeight: 'normal', marginBottom: 0 }}>{`${currentEventSlots[key].length} créneau(x) disponible`}</p>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="clt_select_timeslot_accordion_body">
                                              <div className="no-data-found my-3">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                  <img src={NoResultFound} alt={IMG_ALT} height={'auto'} width={'150'} />
                                                  <div className=" ml-0 d-flex flex-column justify-content-center align-items-center clt-booking-cp-div3-text clt-booking-cp-div3-textt">
                                                    <h2>Aucun créneau disponible</h2>
                                                    <p>Aucun créneau n'est disponible après vos dates</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <CustomHr hrClass="p-0 m-0 clt-hr" />
                                        </>
                                        :
                                        <>
                                          <Accordion.Item
                                            eventKey={`${currentEventSlots[key]}`}
                                            //className="clt-section-5-accordion-item"
                                            style={{ border: 'none' }}
                                          >
                                            <Accordion.Header className="clt-timeslots-accordion-header">
                                              <div>
                                                <p style={{ marginBottom: 0 }}>Après vos dates</p>
                                                <p style={{ color: '#70757a', fontWeight: 'normal', marginBottom: 0 }}>{`${currentEventSlots[key].length} créneau(x) disponible`}</p>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="clt_select_timeslot_accordion_body">
                                              <DisplayData
                                                data={currentEventSlots[key]}
                                                setSelectedTimeSlot={setSelectedTimeSlot}
                                                setSelectedDate={setSelectedDate}
                                                setSelectedPrice={setSelectedPrice}
                                                setpriceDetailsSlots={setpriceDetailsSlots}
                                                isReserveSelectedTimeSlot={isReserveSelectedTimeSlot}
                                                setIsReserveSelectedTimeSlot={setIsReserveSelectedTimeSlot}
                                                isEqual={isEqual}
                                              />
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <CustomHr hrClass="p-0 m-0 clt-hr" />
                                        </>
                                      : null
                              }
                            </div>
                          ))
                          :
                          <>{isLoadingDate ? (<div className="d-flex align-items-center justify-content-center" style={{ height: '60vh' }}>
                            <LoaderDots theme="muted" />
                          </div>) : null
                          }
                          </>
                          // <>{isLoadingDate ? (<h2>Please Wait</h2>) : (<h2 className="clt-no-slot-error">No time slot is available in this range of time</h2>)}</>

                        }
                      </Accordion>
                    </>
                  ) : (
                    <Row>
                      <Col>
                        <div className="clt-datepicker-sidebar-info">
                          <i className="fa fa-info-circle" aria-hidden="true" />
                          <span>
                            Sélectionnez les dates de votre événement pour voir les créneaux disponibles.
                          </span>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="clt-datepicker-sidebar-footer-space" />
                </div>
              </>
            )}

            {/* Body - Booking - Screens : bookingconfirmAndPay */}
            {bookingScreens.bookingconfirmAndPay && (
              <>
                <div className="clt_mybooking_offcanvas_body">
                  <Row>
                    <Col>
                      <div id="targetDiv" className="d-flex justify-content-start align-items-center clt-booking-cp-div1">
                        <img src={ConfirmPay1} alt={IMG_ALT} />
                        <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div1-text">
                          <h2>Réservation instantanée</h2>
                          <p>Votre réservation sera immédiatement acceptée</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <SectionTop
                    image={ConfirmPayProfilePicture1}
                    title="CLT"
                    subTitle1={`Évenement ${eventType?.title}`}
                    subTitle2={`Total ${calculateTotal()}`}
                  />
                </div>
                <CustomHr
                  rowClass="p-0 m-0"
                  colClass="clt-booking-hr-pd p-0 w-100"
                  hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_mybooking_offcanvas_body">
                  <h2 className="clt-booking-cp-div1-title">Votre réservation</h2>
                  <SectionEvent
                    image={eventType.iconActive}
                    subText={eventType.title}
                    setIsEventSidebar={() => setIsEventSidebar(true)}
                    buttonText="Modifier"
                    event={eventType}
                  />
                  <SectionDate
                    image={ConfirmPay2}
                    handleDateEdit={handleDateEdit}
                    subText1={isReserveSelectedTimeSlot?.startDate || myBooking?.ChildTimeSlot?.startDate}
                    subText2={isReserveSelectedTimeSlot?.endDate || myBooking?.ChildTimeSlot?.endDate}
                    selectedTimeSlot={selectedTimeSlot}
                    isDateSelect={true}
                  />
                  <SectionGuest
                    image={ConfirmPay3}
                    subText={
                      guestCount ? `${guestCount} invités` : "Ajouter le nombre d'invité"
                    }
                    setIsGuestSidebar={() => setIsGuestSidebar(true)}
                    buttonText={guestCount ? "Modifier" : "Ajouter"}
                    selectedTimeSlot={selectedTimeSlot}
                  />
                  {selectedSlotRooms?.length > 0 &&
                    <>
                      <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                      <h2 className="clt-booking-cp-div1-title">Option</h2>
                      <SectionBed
                        title="Chambre"
                        image={ConfirmPay5}
                        subText={"Ajouter chambre"}
                        roomSelected={bedCount}
                        setIsBedSidebar={() => setIsBedSidebar(true)}
                        buttonText={bedCount.length > 0 ? "Modifier" : "Ajouter"}
                      />
                    </>
                  }
                  {currentTimeSlot && currentTimeSlot?.isOption2Available ? <>
                    <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <h2 className="clt-booking-cp-div1-title">Option</h2>
                    {/*<p className="clt_admin_dash_timeslot_edit_box_subheading">Les options sont sur demande, vous pouvez en ajouté en nous contactant après le passage de votre réservation</p>*/}
                    <SectionItemAdditional
                      image={img_section_info}
                      subText="Aucune option"
                      optionDetail={true}
                      handleSidebarOpen={openProductsSidebarList}
                      buttonText="Voir  option"
                      hideButton={true}
                    />
                  </>
                    : null}
                </div>
                <CustomHr
                  rowClass="p-0 m-0"
                  colClass="clt-booking-hr-pd p-0 w-100"
                  hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_mybooking_offcanvas_body">
                  <SectionPriceDetail
                    title="Détail du prix"
                    priceListData={priceDetailsSlots.concat(
                      priceDetailsRooms,
                      priceDetailsDamage
                    )}
                    totalPrice={calculateTotal()}
                  />
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <SectionPaymentPlan
                    title="Sélectionnez votre plan de paiement"
                    paymentPlanList={[
                      {
                        id: 1,
                        title: "Pay in 1 time",
                        plan: ["15€"],
                      },
                      {
                        id: 2,
                        title: "Pay in 3 times",
                        plan: ["5€ now", "5€ in 30 days", "5€ in 60 days."],
                      },
                    ]}
                    selectedPlan={selectedPlan}
                    selectedTimeSlot={selectedTimeSlot}
                    setSelectedPlan={(plan) => setSelectedPlan(plan)}
                    priceListData={priceDetailsSlots.concat(
                      priceDetailsRooms,
                      priceDetailsDamage
                    )}
                  /* moreInfo="More information" */
                  />

                </div>
                <CustomHr
                  rowClass="p-0 m-0"
                  colClass="clt-booking-hr-pd p-0 w-100"
                  hrClass="p-0 m-0 clt-hr-2"
                />

                <div className="clt_mybooking_offcanvas_body">
                  <h2 className="clt-booking-cp-div1-title">Information réservation</h2>
                  <SectionProfile
                    handleClick={isLoggedIn ? () => setIsAccountInfo(true) : toggleAuthModal}
                    text={
                      selectedProfile.type === "Personal"
                        ? "Profil Personnel"
                        : selectedProfile.type === "Professional"
                          ? "Profil Professionnel"
                          : "Profil"
                    }
                    subText={
                      selectedProfile.detail !== ""
                        ? <span>{selectedProfile.detail}</span>
                        : isLoggedIn
                          ? <span>Sélectionner un profil</span>
                          : <span>Connexion/Inscription</span>
                    }
                  />

                  <SectionPaymentMethod
                    text="Moyen de paiement"
                    image={ConfirmPay4}
                    // subText={
                    //   isPaymentCardSelected ? (
                    //     <span>**** {isPaymentCardSelected?.number}</span>
                    //   ) : (
                    //     <span>Select a credit card</span>
                    //   )
                    // }
                    subText={
                      isPaymentCardSelected?.type === 'card' ? (
                        <span>{isPaymentCardSelected?.number}</span>
                      ) : isLoggedIn ? (
                        <span>Sélectionner un moyen de paiement</span>
                      ) : (
                        <span>Connexion/Inscription</span>
                      )
                    }
                    // handleClick={() => setIsPaymentSidebar(true)}
                    handleClick={handlePaymentMethods}

                  // buttonText={isPaymentCardSelected ? "Edit" : "Add"}
                  />

                  <SectionClickable2
                    title="Règles et conditions"
                    innerTitle="Règles et conditions"
                    innerSubTitle="Afficher les règles et conditions"
                    onClick={() => setIsSection6SiderbarDetail(true)}
                  />

                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <Row>
                    <Col>
                      <div className="clt-booking-cp-div7-btn">
                        <Button disabled={paybuttondisable} className="shadow-none" onClick={handlePay}>
                          <span>Payer</span>
                        </Button>
                      </div>
                      <p className="clt-booking-cp-div7-p">
                        En réservant, vous acceptez les{' '}
                        <a href="https://docs.google.com/document/d/1VtDKrwp799abiw2n2xT8GikhOCu67X2BB1qimL5g_EM/edit?usp=sharing" target="_blank" rel="noopener noreferrer" className="">
                          Conditions générales de ventes
                        </a>{' '}
                      </p>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            {/* Body - Booking - Screens : bookingsuccess */}
            {bookingScreens.bookingsuccess && (
              <>
                <div className="clt_mybooking_offcanvas_body">
                  <Row>
                    <Col>
                      <div
                        className="d-flex clt-confirm-booking-top-1-div"
                        style={{
                          backgroundColor: `${eventType.background}`,
                          backgroundImage: `url(${eventType.img})`,
                        }}
                      >
                        <span className="clt-confirm-booking-top-1-span">
                          #{myBooking?.id}
                        </span>
                        <h2>
                          {name}, votre réservation est confirmée !
                        </h2>
                      </div>
                    </Col>
                  </Row>
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-start align-items-center clt-booking-cb-div2">
                        <i className="fa fa-info-circle" aria-hidden="true" />
                        <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cb-div2-text">
                          <h2>Réservation confirmée</h2>
                          <p>
                            Vous avez reçu une confirmation de votre réservation par
                            email, la réservation peut être gérée depuis votre compte.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CustomHr
                  rowClass="p-0 m-0"
                  colClass="clt-booking-hr-pd p-0 w-100"
                  hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_mybooking_offcanvas_body">
                  <SectionTop
                    image={ConfirmPayProfilePicture1}
                    title="Château Latournelle"
                    heading="Votre réservation"
                    subTitle1={`Évenement ${eventType?.title}`}
                    subTitle2={`Total ${calculateTotal()}`}

                  />
                  <SectionEvent
                    image={event && event?.dataValues?.icon}
                    subText={eventType.title}
                    event={event?.dataValues}
                  />
                  {/* <SectionDate
                  image={ConfirmPay2}
                  subText1="Subtext 1"
                  subText2={"Subtext 2"}
                  isDateSelect={true}
                /> */}
                  <SectionDate
                    image={ConfirmPay2}
                    // subText1={selectedBooking.ChildTimeSlot?.startDate}
                    // subText2={selectedBooking.ChildTimeSlot?.endDate}
                    // selectedTimeSlot={{ startHour: selectedBooking.ChildTimeSlot?.startHour, endHour: selectedBooking.ChildTimeSlot?.endHour }}
                    // isDateSelect={true}
                    subText1={isReserveSelectedTimeSlot?.startDate}
                    subText2={isReserveSelectedTimeSlot?.endDate}
                    selectedTimeSlot={selectedTimeSlot}
                    isDateSelect={true}
                  />
                  {/* <SectionGuest image={ConfirmPay3} subText="Subtext" /> */}
                  <SectionGuest
                    image={ConfirmPay3}
                    subText={
                      guestCount ? `${guestCount} invités` : "Aucun invité ajouté"
                    }
                  />
                  <SectionBed
                    title="Chambre"
                    image={ConfirmPay5}
                    subText={"Aucune chambre ajoutée"}
                    roomSelected={bedCount}
                  />


                </div>
                {!myBooking?.BookingDocuments?.some(x => x.type === 'docA') || !myBooking?.BookingDocuments?.some(x => x.type === 'docB') || !myBooking?.BookingDocuments?.some(x => x.type === 'docC') ? (
                  <>
                    <CustomHr
                      rowClass="p-0 m-0"
                      colClass="clt-booking-hr-pd p-0 w-100"
                      hrClass="p-0 m-0 clt-hr-2"
                    />
                    <div className="clt_mybooking_offcanvas_body">
                      <h2 className="clt-mybooking-title2">Requis</h2>
                      {!myBooking?.BookingDocuments?.some(x => x.type === 'docA') || !myBooking?.BookingDocuments?.some(x => x.type === 'docC') ? (
                        <SectionRequired
                          innerTitle="Carte identité requise"
                          innerSubTitle="Action nécessaire pour votre réservation"
                          onClick={() => setIsSidebarDocumentRequire(true)}
                          ButtonText="Ajouter document"
                        />
                      ) : null}
                      {!myBooking?.BookingDocuments?.some(x => x.type === 'docB') ? (
                        <SectionRequired
                          innerTitle="Attestation d'assurance responsabilité civile requise"
                          innerSubTitle="Action nécessaire pour votre réservation"
                          onClick={() => setIsSidebarDocumentRequire(true)}
                          ButtonText="Ajouter document"
                        />
                      ) : null}
                    </div>
                  </>
                ) : null}
                <CustomHr
                  rowClass="p-0 m-0"
                  colClass="clt-booking-hr-pd p-0 w-100"
                  hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_mybooking_offcanvas_body">
                  <h2 className="clt-booking-cp-div1-title">Information réservation</h2>
                  <SectionClickable
                    title="Facture"
                    innerTitle="Facture"
                    innerSubTitle="Afficher facture"
                    onClick={() => openInvoices()}
                  />
                  <CustomHr colClass="clt-booking-hr-pd-0" hrClass="p-0 m-0 clt-hr" />
                  <SectionClickable3
                    innerTitle="Document obligatoire"
                    innerSubTitle="Afficher document obligatoire"
                    onClick={() => setIsSidebarDocumentRequire(true)}
                  />
                  <CustomHr colClass="clt-booking-hr-pd-0" hrClass="p-0 m-0 clt-hr" />
                  <SectionClickable
                    innerTitle="Conditions réservation"
                    innerSubTitle="Afficher conditions de réservation"
                    onClick={() => setIsSection6SiderbarDetail(true)}
                  />
                  <CustomHr colClass="clt-booking-hr-pd-0" hrClass="p-0 m-0 clt-hr" />
                  <SectionAccess
                    innerTitle="Remise des clés"
                    innerSubTitle="Remise en main propre"
                    onClick={() => setIsSidebarAccess(true)}
                  />
                  {
                    isProductsSidebarOpen && (
                      <>
                        {currentTimeSlot?.isOption2Available && (
                          <ProductsOptionMariage
                            products={section4ProductsOptionMariage}
                            handleProductSlider={isProductsSidebarOpen}
                            sliderProps={{
                              responsive: section4SliderResponsive,
                              arrows: false,
                              shouldResetAutoplay: false,
                              renderButtonGroupOutside: true,
                              customButtonGroup: <DetailMainSliderArrows title="Option" />,
                              className: "clt-detail-left-section-4-cards-div",
                            }}
                            customButtonGroupTitle="Option"
                          />
                        )}
                      </>
                    )
                  }

                </div>




                <CustomHr
                  rowClass="p-0 m-0"
                  colClass="clt-booking-hr-pd p-0 w-100"
                  hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_mybooking_offcanvas_body">
                  <Row>
                    <Col>
                      <div className="">
                        <div className="clt-section-8 mt-0">
                          <div className="d-flex flex-column mb-5">
                            <a href="https://chateaulatournelle.com/">Accueil</a>
                            <Link to={"/aide"}>Centre aide</Link>
                          </div>
                          <div>
                            <p>Château Latournelle</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            {/* Body - Booking - Screens : bookinginformationMsg */}
            {bookingScreens.bookinginformationMsg && (
              <>
                <Row>
                  <Col>
                    <div className="d-flex flex-column justify-content-center align-items-center clt-cancel-booking">
                      <i className="fa fa-info clt-cancel-booking"></i>
                      <h2>Êtes vous sur ?</h2>
                      <p>
                        Subtext with <Link to={"/"}>text Link</Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Offcanvas.Body>



          {/* Footer - Booking - Screens : bookingdateAndTimeSlots */}
          <div>
            {bookingScreens.bookingdateAndTimeSlots && (
              <>
                <div className="clt-datepicker-sidebar-footer">
                  <div className="inner-div">
                    <Button
                      onClick={handleSelectDataTimeSlot}
                      className="clt-datepicker-sidebar-footer-btn shadow-none"
                      disabled={isReserveSelectedTimeSlot ? false : true}
                      style={
                        eventType && {
                          background: eventType.background,
                          border: `1px solid ${eventType.background}`,
                        }
                      }
                    >
                      {open && <ContactUsScreen isOpen={open} setIsOpen={setOpen} />}
                      <span>Continuer</span>
                    </Button>
                  </div>
                </div>
              </>
            )}

            

            {/* Footer - Booking - Screens : bookinginformationMsg */}
            {bookingScreens.bookinginformationMsg && (
              <>
                <div className="clt-datepicker-sidebar-footer">
                  <div className="inner-div">
                    <Button
                      onClick={() => {
                        if (isFromEditRequest) {
                          SuccessToast("Demande de modification envoyée", "Vous pouvez voir votre demande en haut de votre réservation")
                        }
                        onCloseBookingSidebar("Notification");
                        setBookingScreens(initialBookingScreens);
                      }}
                      className="clt-datepicker-sidebar-footer-btn black_btn shadow-none"
                    >
                      <span>Continuer</span>
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>

        </Offcanvas>

        <InvoiceOffcanvas
          booking={myBooking}
          isInvoiceSidebar={isInvoiceDetail}
          onCloseInvoiceSidebar={setIsInvoiceDetail}
        />

        <AuthModal
          toggleAuthModal={toggleAuthModal}
          isLoginModal={isLoginModal}
        />

        <Account
          isOpenSidebar={isAccountInfo}
          setIsOpen={() => {
            setIsAccountInfo(true);
          }}
          onCloseSidebar={(professionalProfiles) => {
            setIsAccountInfo(false);
          }}
          onCloseProfile={(professionalProfiles, profileType) => {
            Setprofiletype(profileType);
            setMySelectedProfile(professionalProfiles)
            console.log('^^^^^^^^^^^^^^^^^^^^', professionalProfiles)
            setIsAccountInfo(false);
          }}
          selectProfile="select"
          setSelectedProfileData={setSelectedProfile}
        />

        <Payment
          isPaymentSidebar={isPaymentSidebar}
          onClosePaymentSidebar={() => setIsPaymentSidebar(false)}
          isFromBooking={true}
          getPaymentCard={(val) => setIsPaymentCardSelected(val)}
        />

        <Guest
          isGuestSidebar={isGuestSidebar}
          onCloseGuestSidebar={(guestCount) => {
            setIsGuestSidebar(false);
            // setGuestCount(guestCount);
          }}
          selectedTimeSlot={selectedTimeSlot}
        />

        {/* Function - Section - Access */}
        {
          <SidebarAccess
            isSidebarAccess={isSidebarAccess}
            onCloseSidebarAccess={setIsSidebarAccess}
            booking={selectedBooking}
          />
        }

        {/* Function - Section - Document */}
        {isSidebarDocumentRequire ? <SidebarDocumentRequire
          isSidebarDocumentRequire={isSidebarDocumentRequire}
          onCloseSidebarDocumentRequire={setIsSidebarDocumentRequire}
          booking={myBooking}
        /> : null}
        {selectedSlotRooms?.length > 0 &&
          <Bed
            isBedSidebar={isBedSidebar}
            onCloseBedSidebar={() => setIsBedSidebar(false)}
            onConfirm={(val) => {
              setpriceDetailsRooms(
                val?.map((room) => {
                  return {
                    title: `${room?.title} (${room?.quantity} night)`,
                    tooltipMsg: null,
                    price: `${room?.price}€`,
                  };
                })
              );
              setBedCount(val);
            }}
            allRooms={selectedSlotRooms}
            eventType={eventType?.title}
            timeSelected={selectedTimeSlot}
          />
        }

        <Event
          show={isEventSidebar}
          onHide={() => setIsEventSidebar(false)}
          eventType={eventType}
          handleDateEdit={() => handleDateEdit()}
          onConfirmEvent={(event) => {
            if (event.title !== eventType.title) {
              setBookingScreens(initialBookingScreens);
            }
            setEventType(event);
            handleEventchange(event.id);
          }}
          allEvents={allEvents}
        />

        {(Object.keys(event)?.length > 0 && sortedsection6DetailCategory?.length > 0) && (
          <SidebarRuleAndCondition
            isSection6SiderbarDetail={isSection6SiderbarDetail}
            setIsSection6SiderbarDetail={() => setIsSection6SiderbarDetail(false)}
            headerTitle="Header title"
            category1={
              sortedsection6DetailCategory?.length > 0
                ? sortedsection6DetailCategory[0]?.name
                : ""
            }
            column1_1={{
              title: sortedsection6DetailCategory[0]?.rules[0]?.title,
              subTitle: sortedsection6DetailCategory[0]?.rules[0]?.subTitle,
            }}
            column1_2={{
              title: sortedsection6DetailCategory[0]?.rules[1]?.title,
              subTitle: sortedsection6DetailCategory[0]?.rules[1]?.subTitle,
            }}
            column1_3={{
              title: sortedsection6DetailCategory[0]?.rules[2]?.title,
              subTitle: sortedsection6DetailCategory[0]?.rules[2]?.subTitle,
            }}
            column1_4={{
              title: sortedsection6DetailCategory[0]?.rules[3]?.title,
              subTitle: sortedsection6DetailCategory[0]?.rules[3]?.subTitle,
            }}
            column1_5={{
              title: sortedsection6DetailCategory[0]?.rules[4]?.title,
              subTitle: sortedsection6DetailCategory[0]?.rules[4]?.subTitle,
            }}
            column1_6={{
              title: sortedsection6DetailCategory[0]?.rules[5]?.title,
              subTitle: sortedsection6DetailCategory[0]?.rules[5]?.subTitle,
            }}
            column1_7={{
              title: sortedsection6DetailCategory[0]?.rules[6]?.title,
              subTitle: sortedsection6DetailCategory[0]?.rules[6]?.subTitle,
            }}


            category2={sortedsection6DetailCategory[1]?.name}
            column2_1={{
              title: sortedsection6DetailCategory[1]?.rules[0]?.title,
              subTitle: sortedsection6DetailCategory[1]?.rules[0]?.subTitle,
            }}
            column2_2={{
              title: sortedsection6DetailCategory[1]?.rules[1]?.title,
              subTitle: sortedsection6DetailCategory[1]?.rules[1]?.subTitle,
            }}
            column2_3={{
              title: sortedsection6DetailCategory[1]?.rules[2]?.title,
              subTitle: sortedsection6DetailCategory[1]?.rules[2]?.subTitle,
            }}
            column2_4={{
              title: sortedsection6DetailCategory[1]?.rules[3]?.title,
              subTitle: sortedsection6DetailCategory[1]?.rules[3]?.subTitle,
            }}
            column2_5={{
              title: sortedsection6DetailCategory[1]?.rules[4]?.title,
              subTitle: sortedsection6DetailCategory[1]?.rules[4]?.subTitle,
            }}
            column2_6={{
              title: sortedsection6DetailCategory[1]?.rules[5]?.title,
              subTitle: sortedsection6DetailCategory[1]?.rules[5]?.subTitle,
            }}

            column2_7={{
              title: sortedsection6DetailCategory[1]?.rules[6]?.title,
              subTitle: sortedsection6DetailCategory[1]?.rules[6]?.subTitle,
            }}


            category3={sortedsection6DetailCategory[2]?.name}
            column3_1={{
              title: sortedsection6DetailCategory[2]?.rules[0]?.title,
              subTitle: sortedsection6DetailCategory[2]?.rules[0]?.subTitle,
            }}
            column3_2={{
              title: sortedsection6DetailCategory[2]?.rules[1]?.title,
              subTitle: sortedsection6DetailCategory[2]?.rules[1]?.subTitle,
            }}
            column3_3={{
              title: sortedsection6DetailCategory[2]?.rules[2]?.title,
              subTitle: sortedsection6DetailCategory[2]?.rules[2]?.subTitle,
            }}
            column3_4={{
              title: sortedsection6DetailCategory[2]?.rules[3]?.title,
              subTitle: sortedsection6DetailCategory[2]?.rules[3]?.subTitle,
            }}

            category4={sortedsection6DetailCategory[3]?.name}
            column4_1={{
              title: sortedsection6DetailCategory[3]?.rules[0]?.title,
              subTitle: sortedsection6DetailCategory[3]?.rules[0]?.subTitle,
            }}
            column4_2={{
              title: sortedsection6DetailCategory[3]?.rules[1]?.title,
              subTitle: sortedsection6DetailCategory[3]?.rules[1]?.subTitle,
            }}
            column4_3={{
              title: sortedsection6DetailCategory[3]?.rules[2]?.title,
              subTitle: sortedsection6DetailCategory[3]?.rules[2]?.subTitle,
            }}
            column4_4={{
              title: sortedsection6DetailCategory[3]?.rules[3]?.title,
              subTitle: sortedsection6DetailCategory[3]?.rules[3]?.subTitle,
            }}

          />
        )}
      </div>
    </>
  );
};

export default Booking;
