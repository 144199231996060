import React, { useState, useEffect, useRef } from "react";
import info_icon from '../../../assets/img/info_icon.png'; // Info icon import
import cancel_icon from '../../../assets/img/cancel_icon.png';
import illustration_pack_service_climatiseur from '../../../assets/img/illustration_pack_service_climatiseur.png';
import illustration_pack_service_menage from '../../../assets/img/illustration_pack_service_menage.png';
import illustration_pack_service_mobilier_classique from '../../../assets/img/illustration_pack_service_mobilier_classique.png';
import illustration_pack_service_mobilier_bois from '../../../assets/img/illustration_pack_service_mobilier_bois.png';
import illustration_pack_service_suite_des_marriées from '../../../assets/img/illustration_pack_service_suite_des_marriées.png';
import illustration_pack_service_cour from '../../../assets/img/illustration_pack_service_cour.png';
import illustration_pack_service_salle_de_reception from '../../../assets/img/illustration_pack_service_salle_de_reception.png';
import illustration_pack_service_tout_le_chateau from '../../../assets/img/illustration_pack_service_tout_le_chateau.png';
import illustration_pack_service_chaise_bois from '../../../assets/img/illustration_pack_service_chaise_bois.png';
import illustration_pack_service_table_honneur from '../../../assets/img/illustration_pack_service_table_honneur.png';
import illustration_pack_service_dortoir from '../../../assets/img/illustration_pack_service_dortoir.png';




const PackTable4562 = () => {
  const [activePopup, setActivePopup] = useState(null);
  const popupRef = useRef(null);
  const infoContent = [
    {
      id: 1,
      text: "There is a description for the popup under the button clicked",
      image: illustration_pack_service_salle_de_reception
    },
    {
      id: 2,
      text: "Clim/Chauffage",
      text2: "Profitez du chauffage et de la climatisation durant votre réservation",
      image: illustration_pack_service_climatiseur // Sample image URL
    },
    {
      id: 3,
      text: "Ménage",
      text2: "Le ménage sera effectué par le château",
      image2: illustration_pack_service_menage
    },
    {
      id: 4,
      text: "Mobilier classique",
      text2: "Le mobilier classique est inclus dans votre réservation",
      image: illustration_pack_service_mobilier_classique // Another sample image
    },
    {
      id: 5,
      text: "This is the 'Mobilier bois' section with some additional details.",
      image: illustration_pack_service_mobilier_bois // Example image
    },
    {
      id: 6,
      text: "Explanation for the 'Suite des mariées et Mezzannines' service.",
      image: illustration_pack_service_suite_des_marriées
    },
    {
      id: 7,
      text: "Information about the 'Tout le château' package with complete access.",
      image: illustration_pack_service_dortoir
    },
    {
      id: 8,
      text: "Tarification pour un mariage entre le 1er mai au 31 octobre",
      text2: "Haute saison",
      image: null
    },
    {
      id: 9,
      text: "Tarification pour un mariage entre le 1er novembre au 30 avril.",
      text2: "Basse saison",
      image: null
    },
    {
      id: 10,
      text: "Tarification pour un mariage entre le 1er novembre au 30 avril.",
      image: illustration_pack_service_cour
    },
    {
      id: 11,
      text: "Tarification pour un mariage entre le 1er novembre au 30 avril.",
      image: illustration_pack_service_chaise_bois
    },
    {
      id: 12,
      text: "Tarification pour un mariage entre le 1er novembre au 30 avril.",
      image: illustration_pack_service_table_honneur
    }
  ];

  const handleInfoClick = (id) => {
    setActivePopup(id === activePopup ? null : id); // Toggle the popup visibility
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setActivePopup(null); // Close the popup
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <section className="pack-table-4562">
       <h2 className="dskjndslds azffazfsqf azfazsqfs">Réservation</h2>
      <h2 className="clt-detail-left-section-2-h2 zfezefzdezf">Les packs</h2>
      <p className=" uihdeizuhdiez gregergege dvsdvsdvsd">
        Pour effectuer un mariage au château, un "pack" comprenant des services doit être sélectionné
      </p>
      <div className="clt-detail-left-section-4-row rowazza">
      <table>
      <colgroup>
        <col style={{ width: '31%' }} /> {/* Service Column */}
        <col style={{ width: '23%' }} /> {/* Pack Standard */}
        <col style={{ width: '23%' }} /> {/* Pack Intermédiaire */}
        <col style={{ width: '23%' }} /> {/* Pack Premium */}
      </colgroup>
        <thead>
          <tr>
            <th className="jdenzkejzk dkeldlekfeefe">Service</th>
            <th className="jdenfddfzkejzk dkeldlekfeefe">Pack <br /> Standard</th>
            <th className="jdenzkejzkfssff dkeldlekfeefe">Pack <br /> Intermédiaire</th>
            <th className="jdenzkejzkfssff dkeldlekfeefe">Pack <br /> Premium</th>
          </tr>
        </thead>
        <tbody>



        <tr>
            <td className="service-cell edzeezdez cdzkendezk">
              Cour
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(10)} 
              />
              {activePopup === 10 && <div className="popup " ref={popupRef}> 
                <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />
                <img src={infoContent[9].image} alt="info" />
                
              </div>}
            </td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>


          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Salle de réception
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(1)} 
              />
              {activePopup === 1 && <div className="popup " ref={popupRef}> 
                <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />
                <img class="fenfklezn" src={infoContent[0].image} alt="info" />
              </div>}
            </td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>


          



          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Clim/chauffage
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(2)} 
              />
              {activePopup === 2 && <div className="popup" ref={popupRef}> 
                <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon FAEFEALJNFKJ"
                  onClick={() => setActivePopup(null)}
                />
                <img class="climatiseur_image" src={infoContent[1].image} alt="info" />
                <p class="popup-pack-title">{infoContent[1].text}</p>
                <p>{infoContent[1].text2}</p>
                
              </div>}
            </td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>


          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Ménage
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(3)} 
              />
              {activePopup === 3 && <div className="popup" ref={popupRef}>
                <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon FAEFEALJNFKJ"
                  onClick={() => setActivePopup(null)}
                />
                 <img class="climatiseur_image" src={infoContent[2].image2} alt="info" />
                 <p class="popup-pack-title">{infoContent[2].text}</p>
                <p>{infoContent[2].text2}</p>
              </div>}
            </td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>

          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Suite des mariées
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(6)} 
              />
              {activePopup === 6 && <div className="popup dekzmamma" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />
              <img src={infoContent[5].image} alt="info" />
              </div>}
            </td>
            <td className="no-4562">❌</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>




          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Mobilier - Classique
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(4)} 
              />
              {activePopup === 4 && <div className="popup ZEFZ" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />
                <img src={infoContent[3].image} alt="info" />
              </div>}
            </td>
            <td className="no-4562">❌</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>

          


          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
            Mobilier - Table d'honneur
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(12)} 
              />
              {activePopup === 12 && <div className="popup dekzmamma" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />
                <img src={infoContent[11].image} alt="info" />
              </div>}
            </td>
            <td className="no-4562">❌</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>

          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
            Mobilier - Chaise Bois
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(11)} 
              />
              {activePopup === 11 && <div className="popup dekzmamma" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />
                <img src={infoContent[10].image} alt="info" />
              </div>}
            </td>
            <td className="no-4562">❌</td>
            <td className="check-4562">✔</td>
            <td className="check-4562">✔</td>
          </tr>

          



          


          
          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Mobilier - Bois
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(5)} 
              />
              {activePopup === 5 && <div className="popup dezjdeoziuhd" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />

                <img src={infoContent[4].image} alt="info" />
              </div>}
            </td>
            <td className="no-4562">❌</td>
            <td className="no-4562">❌</td>
            <td className="check-4562">✔</td>
          </tr>



          
          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Dortoir
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(7)} 
              />
              {activePopup === 7 && <div className="popup dezjdecsqccccqoziuczzhd" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon zkjnkjdnze"
                  onClick={() => setActivePopup(null)}
                />
                <img src={infoContent[6].image} alt="info" />
              </div>}
            </td>
            <td className="no-4562">❌</td>
            <td className="no-4562">❌</td>
            <td className="check-4562">✔</td>
          </tr>
          <tr>
            <td className="service-cell dkeldlek cdzkendezk">
              Prix - Haute saison
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(8)} 
              />
              {activePopup === 8 && <div className="popup vdssdsdv dezjdecsqccccqoziuhd" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon effzsfsdf"
                  onClick={() => setActivePopup(null)}
                />
                <p class="dzkdnzdzk popup-pack-title">{infoContent[7].text2}</p>
                <p class="dzkdnzdzk">{infoContent[7].text}</p>
              </div>}
            </td>
            <td>3450€</td>
            <td>4450€</td>
            <td>5450€</td>
          </tr>
          <tr>
            <td className="service-cell czdcdzdzsc cdzkendezk">
              Prix - Basse saison
              <img
                src={info_icon}
                alt="Info"
                className="info-icon"
                onClick={() => handleInfoClick(9)} 
              />
              {activePopup === 9 && <div className="popup vdssdsdv ffzefxxxx" ref={popupRef}> 
              <img
                  src={cancel_icon}
                  alt="Close"
                  className="close-icon effzsfsdf"
                  onClick={() => setActivePopup(null)}
                />
                <p class="dzkdnzdzk popup-pack-title">{infoContent[8].text2}</p>
                <p class="dzkdnzdzk">{infoContent[8].text}</p>
              </div>}
            </td>
            <td>2950€</td>
            <td>3950€</td>
            <td>4950€</td>
          </tr>
        </tbody>
      </table>
      </div>
    </section>
  );
};

export default PackTable4562;
