const priceSimulatorObject = {   
    'Developper': {
        "data" : {
            "Janvier-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            
            "Janvier-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },

            "Janvier-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },

            "Janvier-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },

            "Janvier-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },

            "Janvier-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },

            "Janvier-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },


            "Janvier-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Fevrier-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mars-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Avril-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Mai-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juin-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Juillet-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Août-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Septembre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Octobre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 3450 } },
            "Novembre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },
            "Decembre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 18:00)": 2950 } },

        }
    },
    'Mariage': {
        "data" : {
            "Janvier-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2023": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
            "Janvier-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2024": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
            "Janvier-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2025": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
            "Janvier-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2026": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
            "Janvier-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2027": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
            "Janvier-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2028": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
            "Janvier-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2029": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
            "Janvier-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Fevrier-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mars-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Avril-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Mai-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juin-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Juillet-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Août-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Septembre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Octobre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 3450, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 3450 } },
            "Novembre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            "Decembre-2030": { "price": { "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00) ": 2950, "3 jours et 2 nuits (Exemple: Vendredi 12:00 > Dimanche 20:00)": 2950 } },
            
        }
    },
    'Repas noël': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }
    },
    'Repas': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }
    },
    'Convention': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }
    },
    'Séminaire': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }
    },
    'Soirée': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }

    },
    'Cousinade': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }
    },
    'Anniversaire': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }
    },
    
    'Baptème': {
        "data" : {
            "Janvier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2023": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2024": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2025": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2026": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2027": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2028": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2029": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            "Janvier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Fevrier-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mars-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Avril-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Mai-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juin-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Juillet-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Août-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Septembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Octobre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 3450 }},
            "Novembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},
            "Decembre-2030": { "price": { "Matin (08:00-12:00)": 500, "Après-midi (14:00-20:00)": 600, "Journée (08:00-20:00)": 1000, "Après midi + Soirée (14:00-01:00)": 1500, "Après midi + Nuit (14:00-08:00)": 1800, "24h (Exemple: Mercredi 08:00 > Jeudi 08:00)": 2000, "36h (Exemple: Mercredi 08:00 > Jeudi 20:00)": 2450, "3 jours et 2 nuits (Exemple: Mercredi 08:00 > Vendredi 20:00)": 2950 }},


            
        }
    
    }
}

export default priceSimulatorObject;