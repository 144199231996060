import React, { useEffect, useState } from "react";
import { Col, Row, Container } from 'react-bootstrap';
import CustomCarousel from "../CustomCarousel";
import {
  IMG_ALT,
  selectRoomSliderResponsive
} from "../../../constants";
import CustomOffCanvas from "../CustomOffCanvas";
import detail_slider_1_1 from "../../../assets/img/detail_slider_1_1.png";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import CustomHr from "../CustomHr";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import admin_panel_first_section_desktop from "../../../assets/img/admin_panel_first_section_desktop.png";
import image1 from '../../../assets/img/room_slider_1.jpg';
import image2 from '../../../assets/img/room_slider_2.jpg';
import image3 from '../../../assets/img/room_slider_3.jpg';
import image4 from '../../../assets/img/room_slider_4.jpg';
import image5 from '../../../assets/img/room_slider_5.jpg';
import Florist1_1 from '../../../assets/img/Florist1_1.jpg';
import imginfo from '../../../assets/img/img_section_info.png';
import image6 from '../../../assets/img/room_slider_6.png';
import image7 from '../../../assets/img/room_slider_7.png';
import icon_instagram from '../../../assets/img/icon_instagram.png';
import confirm_pay_2 from '../../../assets/img/confirm_pay_2.png';


import phone_icon from '../../../assets/img/phone_icon.png';


const section4ProductsPrestataireMariage = [
  {
    img: Florist1_1,
    name: "Madame fleur & Monsieur graine",
    secondary_text: "Prestataire",
    description: "Fleuriste",
  }
  // ... (other section4ProductsPrestataireMariage data)
];

const section4ProductsSidebar = [
  {
    category: "Prestataire",
    products: [
      {
        img: Florist1_1,
        name: "Madame fleur & Monsieur graine",
        secondary_text: "Prestataire",
        description: "Fleuriste",
        service: "Fleur",
        service1: "Composition",
        service2: "Bouquet",
        service3: "Décoration",
        service4: "Plante",
        service5: "Accessoire",


        subTexts: [
          { service: "-Fleur", service1: "-Composition", service2: "-Bouquet", service3: "-Décoration", service4: "-Plante", service5: "-Accessoire" },
   
          // Add other objects as needed
        ],
        images: [
          Florist1_1
        ]
      },
      // ... (other products data)
    ],
  },
  // ... (other categories data)
];

const images = [
  "detail_slider_1_1.png",
  "detail_slider_1_1.png",
  "detail_slider_1_1.png",
  // Add more image URLs as needed
];

const ProductsPrestataireMariage = ({ products, handleProductSlider, sliderProps, customButtonGroupTitle, isMobile }) => {

  const [isProductsSidebarOpen, setIsProductsSidebarOpen] = useState(false);
  const [isProductsSidebarList, setIsProductsSidebarList] = useState(true);
  const [isProductsSiderbarDetail, setIsProductsSiderbarDetail] = useState(false);
  const [productDetail, setProductDetail] = useState({ name: '' });
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const openGallery = async (type, index, myGallery) => {
    const lightboxGallery = await Promise.all(
      myGallery.map(async (item) => {
        const img = new Image();
        img.src = item?.ImageUrl;
        await img.decode();
        return {
          src: img.src,
          name: item.title,
          width: windowSize?.innerWidth,
          height: (img.height / img.width) * windowSize?.innerWidth,
        };
      })
    );

    let lightbox = {};
    if (type === "main" || type === "secondary") {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",
        children: "a",
        dataSource: lightboxGallery,
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
      lightbox.loadAndOpen(index);
    }
  };

  const handleSidebarOpen = () => {
    setIsProductsSidebarOpen(true);
  };

  return (
    <>
      <Row className="showMobile">
        <Col className="clt-detail-left-section-4-h2-mt clt-section-row-col-pd">
          <h2 className="clt-detail-left-section-4-h2">Prestataire</h2>
          <p className="abaclt_admin_dash_timeslot_edit_box_subheading17584 mt-1">
          Parcourez notre liste de prestataires suggérés pour votre événement
          </p>
        </Col>
      </Row>
      <Row className="clt-detail-left-section-4-row showMobile">
        <Col>
          <Container className="clt-detail-left-section-4-row-container">
            <Row>
              <Col className="clt-detail-left-section-4-row-col">
                <div className="d-flex justify-content-start align-items-center clt-detail-left-section-4">
                  {products?.map((value, index) => (
                    <div key={index}>
                      <div className="clt-detail-left-section-4-card-item cursor-zoom-in" onClick={handleSidebarOpen}>
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="clt-detail-left-section-4-card-item-div" style={{ backgroundImage: `url(${value.img})` }}></div>
                          <div><i className="fa-light fa-chevron-right"></i></div>
                        </div>
                        <h2 className="clt-detail-left-section-4-card-item-h2">{value.name}</h2>
                        <p className="clt-detail-left-section-4-card-item-secondary">{value.secondary_text}</p>
                        <p className="clt-detail-left-section-4-card-item-desc">{value.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="hideMobile">
        <Col className="clt-detail-left-section-4-col">
          <div className="d-flex flex-column-reverse">
            <CustomCarousel {...sliderProps}>
              {products?.map((value, index) => (
                <div key={index} className="clt-detail-left-section-4-card-item cursor-zoom-in" onClick={handleSidebarOpen}>
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="clt-detail-left-section-4-card-item-div" style={{ backgroundImage: `url(${value.img})` }}></div>
                    <div><i className="fa-light fa-chevron-right"></i></div>
                  </div>
                  <h2 className="clt-detail-left-section-4-card-item-h2">{value.name}</h2>
                  <p className="clt-detail-left-section-4-card-item-secondary">{value.secondary_text}</p>
                  <p className="clt-detail-left-section-4-card-item-desc">{value.description}</p>
                  </div>
              ))}
            </CustomCarousel>
          </div>
        </Col>
      </Row>

      <CustomOffCanvas
        placement="end"
        className="clt_products_offcanvas"
        show={isProductsSidebarOpen}
        onHide={() => setIsProductsSidebarOpen(false)}
        headerClassName="justify-content-start clt_products_offcanvas_header"
        bodyClassName={`${!isProductsSiderbarDetail ? "clt_products_offcanvas_body clt_products_offcanvas_body-p-0" : "p-0"}`}
        headerTitle={isProductsSidebarList ? "Prestataire" : productDetail.name}
        isBackBtn={true}
        handleBack={() =>
          isProductsSiderbarDetail
            ? (setIsProductsSiderbarDetail(false), setIsProductsSidebarList(true))
            : setIsProductsSidebarOpen(false)
        }
      >
        {isProductsSidebarList && section4ProductsSidebar?.map((val, i) => {
          return (
            <Row key={i} className="clt_products_offcanvas_body_mainrow">
              <Col className="p-0">
                <Row className="clt_products_offcanvas_body_maindiv">
                  <Col>
                    <h2 className="clt_products_offcanvas_body_category">
                      {val.category}
                    </h2>
                  </Col>
                </Row>
                {val.products?.map((value, index) => (
                  <Row
                    key={index}
                    className="clt_products_offcanvas_body_secdiv"
                    onClick={() => {
                      setIsProductsSiderbarDetail(true);
                      setIsProductsSidebarList(false);
                      setProductDetail(value);
                    }}
                  >
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <h2 className="clt_products_offcanvas_body_product">
                        {value.name}
                      </h2>
                      <p className="clt_products_offcanvas_body_secondary">
                        {value.secondary_text}
                      </p>
                      <p className="clt_products_offcanvas_body_desc">
                        {value.description}
                      </p>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={4}>
                      <div className="clt_products_offcanvas_body_img" style={{ backgroundImage: `url(${value.img})`, }}></div>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          );
        })}
        {isProductsSiderbarDetail && (
          <Container className="clt_product_detail_offcanvas_body">
            <Row>
              <Col className="p-0">
                <PhotoProvider>
                  <CustomCarousel
                    responsive={selectRoomSliderResponsive}
                    arrows={true}
                    shouldResetAutoplay={false}
                    renderButtonGroupOutside={true}
                    showDots={true}
                    swipeable={true}
                    className="clt-room-slider"
                  >
                    {productDetail.images.map((galleryPic, gIndex) => (
                      <PhotoView src={galleryPic} key={gIndex}>
                        <div
                          className="d-flex justify-content-start align-items-start clt-room-slider-img-div cursor-zoom-in"
                          style={{
                            backgroundImage: `url(${galleryPic})`,
                          }}
                          onClick={() => openGallery("main", gIndex, productDetail.images)}
                        />
                      </PhotoView>
                    ))}
                  </CustomCarousel>
                </PhotoProvider>
              </Col>
            </Row>
            <Row>
              <Col className="header_text_div">
                <h2>{productDetail.name}</h2>
                <p>{productDetail.secondary_text} · {productDetail.description} </p>
              </Col>
            </Row>
            <CustomHr
              colClass="clt_product_detail_offcanvas_hr_pd"
              hrClass="p-0 m-0 clt-hr"
            />
            <Row>
              <Col className="mt-4 clt-prestataire-option-div clt-detail-footer-mb-col">
                <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
                  <img src={imginfo} alt={IMG_ALT} />
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                      <h2>Prestataire</h2>
                      <p>Ce prestataire est suggéré par le château</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 clt-prestataire-option-div clt-detail-footer-mb-col">
                <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
                  <img src={phone_icon} alt={IMG_ALT} />
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                      <h2>Téléphone</h2>
                      <p>0561594459</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="mt-4 clt-prestataire-option-div clt-detail-footer-mb-col">
                <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
                  <img src={confirm_pay_2} alt={IMG_ALT} />
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                      <h2>Service</h2>
                      <p>{productDetail.service}</p>
                      <p>{productDetail.service1}</p>
                      <p>{productDetail.service2}</p>
                      <p>{productDetail.service3}</p>
                      <p>{productDetail.service4}</p>
                      <p>{productDetail.service5}</p>
                      <p>{productDetail.service6}</p>
                      <p>{productDetail.service7}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="mt-4 clt-prestataire-option-div clt-detail-footer-mb-col">
                <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
                  <img src={icon_instagram} alt={IMG_ALT} />
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                      <h2>Instagram</h2>
                      <p>@madame_fleur_et_m_graine</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>



            <Row>
              {productDetail.subTexts?.map((value, index) => (
                <Col
                  key={index}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="sub_text_div"
                >
                  <h2>{value.title}</h2>
                  <p>{value.subText}</p>
                  <p>{value.subText2}</p>
                  <p>{value.subText3}</p>
                  <p>{value.subText4}</p>
                  <p>{value.subText5}</p>
                  <p>{value.subText6}</p>
                  <p>{value.subText7}</p>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </CustomOffCanvas>
    </>
  );
};

export default ProductsPrestataireMariage;

export { section4ProductsSidebar, section4ProductsPrestataireMariage, images, ProductsPrestataireMariage };

function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}
